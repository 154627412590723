/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { ArrowsUpDownIcon, PlusIcon } from "@heroicons/react/24/outline";
import CourieButton from "@src/common/components/Button/Button";
import React from "react";
import SortField, { SortFieldOption } from "./SortField";
import { useTableControl } from "../context/TableControlContext";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeading,
  PopoverDescription,
} from "@src/common/components/Popover";
import {
  ShipmentsSortField,
  ShipmentsSorting,
  SortingDirection,
} from "@api/graphql/generated/generated-types";

export type PartialShipmentsSorting = {
  direction: SortingDirection;
  field?: ShipmentsSortField;
};

type Props = {
  sortFieldOptions: SortFieldOption[];
};

function SortingOptionsDropdown({ sortFieldOptions = [] }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { sortFields, setSortFields } = useTableControl();
  const [selectedSorts, setSelectedSorts] = useState<PartialShipmentsSorting[]>(
    [{ direction: SortingDirection.Asc, field: undefined }]
  );

  useEffect(() => {
    if (sortFields.length > 0) {
      setSelectedSorts(sortFields as PartialShipmentsSorting[]);
    }
  }, []);

  useEffect(() => {
    const filteredSorts: ShipmentsSorting[] = selectedSorts.filter(
      (sort) => sort.field
    ) as ShipmentsSorting[];

    if (
      JSON.stringify(filteredSorts) !== JSON.stringify(sortFields) &&
      filteredSorts.length > 0
    ) {
      setSortFields(filteredSorts);
      return;
    }
  }, [selectedSorts]);

  useEffect(() => {
    const filteredSorts: ShipmentsSorting[] = selectedSorts.filter(
      (sort) => sort.field
    ) as ShipmentsSorting[];

    if (JSON.stringify(filteredSorts) !== JSON.stringify(sortFields)) {
      setSelectedSorts(sortFields as PartialShipmentsSorting[]);
      return;
    }
  }, [sortFields]);

  const addSelectedSort = () => {
    setSelectedSorts([
      ...selectedSorts,
      { field: undefined, direction: SortingDirection.Asc },
    ]);
  };

  const updateSelectedSort = (index: number, sort: ShipmentsSorting) => {
    const updatedSorts = [...selectedSorts];
    updatedSorts[index] = sort;
    setSelectedSorts(updatedSorts);
  };

  const removeSelectedSort = (index: number) => {
    const removedSort = selectedSorts[index];
    if (removedSort.field) {
      setSortFields(
        selectedSorts.filter((_, i) => i !== index) as ShipmentsSorting[]
      );
    }
    setSelectedSorts(selectedSorts.filter((_, i) => i !== index));
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <CourieButton
          pill={true}
          outline={sortFields.length > 0 ? false : true}
          size="xs"
          color={sortFields.length > 0 ? "yellow" : "secondary"}
          onClick={() => setIsOpen((v) => !v)}
          className="hover:bg-slate-100 border p-0"
        >
          <ArrowsUpDownIcon className="h-4 w-4 mr-1" />
          {sortFields.length > 0 ? (
            <span className="text-primary truncate">
              Sorted by {sortFields.length}{" "}
              {sortFields.length > 1 ? "fields" : "field"}
            </span>
          ) : (
            <span className="">Sort</span>
          )}
        </CourieButton>
      </PopoverTrigger>

      <PopoverContent
        style={{ left: 100 }}
        className="absolute z-30 bg-white border min-w-max p-3 shadow-2xl rounded-md"
      >
        <PopoverHeading className="mb-3">Sort By</PopoverHeading>
        <PopoverDescription>
          <div className="flex flex-col gap-3">
            {selectedSorts.map((sort, index) => (
              <SortField
                sortFieldOptions={sortFieldOptions}
                key={index}
                sort={sort}
                index={index}
                onRemove={removeSelectedSort}
                onUpdate={updateSelectedSort}
              />
            ))}
            <div>
              <CourieButton
                size={"xs"}
                onClick={() => addSelectedSort()}
                color={"secondary"}
              >
                <PlusIcon className="h-4 w-4 mr-1" />
                {selectedSorts.length > 0 ? "Add another sort" : "Add sort"}
              </CourieButton>
            </div>
          </div>
        </PopoverDescription>
      </PopoverContent>
    </Popover>
  );
}

export default SortingOptionsDropdown;
