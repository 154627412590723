import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Button, Loading, Modal } from "@src/common/components";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  loading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  textBody: string;
  primaryButtonColor?: string;
  primaryButtonText?: string;
  cancelButtonText?: string;
};

function WarningConfirmationModal({
  isModalOpen,
  setIsModalOpen,
  loading,
  onConfirm,
  onCancel,
  title,
  textBody,
  primaryButtonColor,
  primaryButtonText,
  cancelButtonText,
}: Props) {
  return (
    <Modal
      show={isModalOpen}
      dismissible={true}
      onClose={() => {
        setIsModalOpen(false);
      }}
      size={"sm"}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <ExclamationCircleIcon className="mx-auto mb-4 h-14 w-14 text-gray-400" />
          <h3 className="mb-5 text-lg font-normal text-gray-500">{textBody}</h3>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-end">
        <Button color="gray" onClick={onCancel} className="truncate">
          {cancelButtonText || "Cancel"}
        </Button>
        <Loading loading={loading}>
          <Button color={primaryButtonColor || "info"} onClick={onConfirm}>
            {primaryButtonText || "Confirm"}
          </Button>
        </Loading>
      </Modal.Footer>
    </Modal>
  );
}

export default WarningConfirmationModal;
