import {
  EndCustomer,
  useSearchEndCustomerLazyQuery,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { Combobox } from "@src/common/components";
import { makeEndCustomerDisplay } from "@src/endcustomers/utils/endCustomerDisplayer";
import { EndCustomerSuggestionDisplay } from "@src/orders/create/components/NewCustomerSuggestionInput";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDebounce } from "usehooks-ts";

type Props = {
  selectedCustomer: EndCustomer | undefined;
  setSelectedCustomer: (customer: EndCustomer | undefined) => void;
  size: "compact" | "default" | "mini" | "large" | undefined;
  error?: boolean;
};

function EndCustomerSearchInput({
  selectedCustomer,
  setSelectedCustomer,
  size,
  error = false,
}: Props) {
  const { courierId } = useContext(AuthContext);
  const inputRef = useRef<HTMLElement>(null);
  const [query, setQuery] = useState("");
  const [options, setOptions] = React.useState<EndCustomer[]>([]);
  const debouncedQueryValue = useDebounce<string>(query, 500);
  const [searchEndCustomerLazyQuery, { data: endCustomersData, loading }] =
    useSearchEndCustomerLazyQuery();

  useEffect(() => {
    if (selectedCustomer) {
      setQuery(selectedCustomer.companyName || "");
    }
    return () => {
      setQuery("");
    };
  }, [selectedCustomer]);

  useEffect(() => {
    const endCustomers: EndCustomer[] =
      (endCustomersData?.searchEndCustomer as EndCustomer[]) || [];
    setOptions(endCustomers);
  }, [endCustomersData]);

  useEffect(() => {
    if (debouncedQueryValue.length > 0) {
      searchEndCustomerLazyQuery({
        variables: {
          courierId: courierId!,
          searchTerm: debouncedQueryValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQueryValue]);

  const handleChangeCustomer = (
    nextValue: string,
    customer?: EndCustomer | null
  ) => {
    setQuery(nextValue);
    if (customer) {
      setSelectedCustomer(customer);
    } else {
      setSelectedCustomer(undefined);
    }
  };

  function mapOptionToString(option: EndCustomer): string {
    if (option.companyName && option.id === selectedCustomer?.id) {
      return option.companyName;
    }
    return "";
  }

  return (
    <div className="mt-1">
      <Combobox
        size={size}
        inputRef={inputRef}
        error={error}
        clearable={true}
        onChange={(b, a) => {
          handleChangeCustomer(b, a as EndCustomer);
        }}
        mapOptionToString={(a) => mapOptionToString(a as EndCustomer)}
        value={query}
        name="customers-options"
        options={options}
        mapOptionToNode={({ option }) => (
          <EndCustomerSuggestionDisplay
            endCustomer={makeEndCustomerDisplay(option as EndCustomer)}
          />
        )}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: {
                  style: {
                    zIndex: 30,
                  },
                },
              },
            },
          },
          Input: {
            props: {
              placeholder:
                "Search by Company, First name, Last Name, Email, Phone, or ID",
              error: error,
              autoComplete: "off",
            },
          },
          ListItem: {
            style: {
              paddingTop: "5px", // Increase padding for larger items, if necessary
              paddingBottom: "5px",
              height: "auto", // Set height to auto, if necessary
              // Any other styles you want to apply
            },
          },
        }}
      />
    </div>
  );
}

export default EndCustomerSearchInput;
