import {
  SortingDirection,
  ShipmentsSorting,
  ShipmentsSortField,
} from "@api/graphql/generated/generated-types";
import { useTableControl } from "@src/shipments/context/TableControlContext";
import { TableColumnType } from "@src/shipments/components/ShipmentTableColumns";

export const useHeaderSorting = () => {
  const { sortFields, setSortFields } = useTableControl();

  const handleSortingClick = (
    sortOrder: SortingDirection,
    column: TableColumnType,
    replaceSortFields: boolean = false
  ) => {
    const newSortField: ShipmentsSorting = {
      field: column.sortFields![0] as ShipmentsSortField,
      direction: sortOrder,
    };
    const isSortIncludedInSortFields = sortFields.some(
      (sortField) =>
        sortField.field === newSortField.field &&
        sortField.direction === newSortField.direction
    );
    const isSortFieldAlreadyIncluded = sortFields.some(
      (sortField) => sortField.field === newSortField.field
    );

    if (isSortIncludedInSortFields) {
      return;
    }

    if (isSortFieldAlreadyIncluded) {
      const shipmentsSorting = sortFields as ShipmentsSorting[];
      const filteredSortFields: ShipmentsSorting[] = shipmentsSorting.filter(
        (sortField) => sortField.field !== newSortField.field
      );
      const updatedSortFields = [newSortField, ...filteredSortFields];
      setSortFields(updatedSortFields);
      return;
    }

    if (column.sortFields) {
      if (replaceSortFields) {
        setSortFields([newSortField]);
      } else {
        setSortFields([newSortField, ...sortFields] as ShipmentsSorting[]);
      }
    }
  };

  const areFieldsSorted = (fields: string[]) => {
    return fields.some((field) =>
      sortFields.some((sortField) => sortField.field === field)
    );
  };

  return { handleSortingClick, areFieldsSorted };
};
